.pyramid_anim_container {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate3d(0, 0, 0);
}

.scope span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform-style: preserve-3d;
}
.scope span:first-child {
  transform: rotateY(calc(00deg)) translateZ(1px) rotateX(0deg);
}
.scope span:nth-child(2) {
  transform: rotateY(calc(0deg)) translateZ(0px) rotateX(0deg);
}

.scope {
  position: relative;
  width: 300px;
  height: 450px;
  transform-style: preserve-3d;
  animation: slid 5s linear infinite;
  border-radius: 9px;
  transition: 1.5s all;
  box-shadow: 0px 55px 88px 0px rgba(0,0,0,0.42);
}
.scope span {
  overflow: hidden;
  border-radius: 12px;
}
.front.active::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 100%;
  display: block;
  background: linear-gradient(112deg,hsla(0,0%,100%,0) 35%,hsla(0,0%,100%,.3) 50%,hsla(0,0%,100%,0) 65%);
  background-position-x: 0;
  background-repeat: no-repeat;
  transition: all .35s ease-in-out;
  pointer-events: none;
  z-index: 2;
  animation: shiningEffect1 2s ease-in-out normal ;
}
.back.active::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 100%;
  display: block;
  background: linear-gradient(250deg,hsla(0,0%,100%,0) 35%,hsla(0,0%,100%,.3) 50%,hsla(0,0%,100%,0) 65%);
  background-position-x: 0;
  background-repeat: no-repeat;
  transition: all .35s ease-in-out;
  pointer-events: none;
  z-index: 2;
  animation: shiningEffect2 2s ease-in-out normal ;
}
.back_card.active::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 100%;
  display: block;
  background: linear-gradient(250deg,hsla(0,0%,100%,0) 35%,hsla(0,0%,100%,.3) 50%,hsla(0,0%,100%,0) 65%);
  background-position-x: 0;
  background-repeat: no-repeat;
  transition: all .35s ease-in-out;
  pointer-events: none;
  z-index: 2;
  animation: shiningEffect2 2s ease-in-out normal ;
}
.badge-img{
  position: absolute;
  width: 75px;
  height: 75px;
  bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  cursor: pointer;
}
.flip-icon {
  /* position: absolute; */
  width: 40px;
  height: 40px;
  /* margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;*/
  cursor: pointer; 
}
.fullscreen-icon{
  /* position: absolute; */
  width: 40px;
  height: 40px;
  /* margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  cursor: pointer;
}
.back_card{
  animation: hideBack 2s ease-in-out normal;
  transition: all 1s;
  visibility: hidden;
}
.back_card.active{
  visibility: visible;
}

@keyframes shiningEffect1 {
  0%{

    left: -100%;
  }
 100%{

  left: 100%;
 }
}
@keyframes shiningEffect2 {
  0%{

    right: -100%;
  }
 100%{
  right: 100%;
 }
}

@keyframes hideBack {
 from{
  visibility: visible;
  }
 to{
    visibility: hidden;
  }
}
.flipped-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  transition: 2s;
}
.card_shadow {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 350px;
  transform: translateY(calc(50% + 150px));
}
.buttonHolder{
  position: fixed;
  height: 16px;
  width: 16px;
  top: 4%;
  left: 4%;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  color: #fff;
  transition: color .3s ease-in-out;
  cursor: pointer;
}
.trait1{
  animation: orangeEffect 10s ease-in-out infinite;
}
.trait2{
  animation: orangeEffect 10s ease-in-out infinite;
  animation-delay: 1.5s;
}
.trait3{
  animation: orangeEffect 10s ease-in-out infinite;
  animation-delay: 3s;
}
.trait4{
  animation: orangeEffect 10s ease-in-out infinite;
  animation-delay: 4.5s;
}
.trait5{
  animation: orangeEffect 10s ease-in-out infinite;
  animation-delay: 6s;
}
.trait6{
  animation: orangeEffect 10s ease-in-out infinite;
  animation-delay: 7.5s;
}
@keyframes orangeEffect {
  0%{
    background-color: black
  }
  5%{
    background-color: #fc6405
  }
 10%{
  background-color: black
 }
}
@media screen and (max-width: 450px) {
  .scope {
    position: relative;
    width: 300px;
    height: 440px;
    transform-style: preserve-3d;
    animation: slid 5s linear infinite;
    transition: 2s all;
  }
  .arrow{
    display: none;
  }
}
@media screen and (max-width: 350px) {
  .badge-img{
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  .scope {
    position: relative;
    width: 250px !important;
    height: 340px;
    transform-style: preserve-3d;
    animation: slid 5s linear infinite;
    transition: 2s all;
  }
}