.markdown{
  font-family: poppins;
  width: 100%;
  margin: auto;
}
.dust-terms-scoll-box::-webkit-scrollbar {
  width: 10px;
  margin-left: 5px;
}

/* Track */
.dust-terms-scoll-box::-webkit-scrollbar-track {
  background: #0000004d;
}

/* Handle */
.dust-terms-scoll-box::-webkit-scrollbar-thumb {
  background: #FF692B;
}

/* Handle on hover */
.dust-terms-scoll-box::-webkit-scrollbar-thumb:hover {
  background: #FF692B;
}

@media screen and (max-width: 450px) {
  .markdown{
    font-size: 12px;
  }
}