.dust-span-container {
  height: 100%;
  background-color: #FC6405;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px
}

.dust-span-container.disable {
  pointer-events: none;
  cursor: none;
}

.dust-item-icon {
  max-height: 15px;
}

.transaction-loading-spinner {
  width: 28px !important;
}

.dust-claim-video-container{
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left:0;
  z-index: 3333;
  background-color: rgba(0, 0, 0, 0.8);
  /* background-color: transparent; */
}
body .dust-claim-video-container iframe{
  display: block!important;
}
.dust-claim-video-container button:hover{
  cursor: pointer;
}
.dust-claim-modal-close-button{
  position: absolute;
    width: 32px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    pointer-events: auto;
}

@media screen and (max-width: 450px) {
  .dust-span-container {
    min-width: 60px
  }
  
}