.card-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 34343445435;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #152D2E;
  overflow: hidden;
}
.explorer-card-image-container{
    position: relative;
    /* min-height: 500px; */
    margin: auto;
    height: 96%;
    width: 96%;

  pointer-events: none;
  }
  .explorer-pyramid_anim_container {
    z-index: 2;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    transform: translate3d(0, 0, 0);
  }
  .resizer-container{
    height: 100%;
    width: 100%;
    pointer-events: all;
  }
  
  .explorer-scope span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
  }
  .explorer-scope span:first-child {
    transform: rotateY(calc(0deg)) translateZ(1px) rotateX(0deg);
  }
  .explorer-scope span:nth-child(2) {
    transform: rotateY(calc(0deg)) translateZ(0px) rotateX(0deg);
  }
  
  .explorer-scope {
    position: relative;
    transform-style: preserve-3d;
    animation: slid 5s linear infinite;
    border-radius: 12px;
    transition: 1.5s all;
    /* box-shadow: 0px 55px 88px 0px rgba(0,0,0,0.42); */
  }
  .explorer-scope span {
    overflow: hidden;
    border-radius: 12px;
  }
  .explorer-front.active::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 100%;
    display: block;
    background: linear-gradient(112deg,hsla(0,0%,100%,0) 35%,hsla(0,0%,100%,.3) 50%,hsla(0,0%,100%,0) 65%);
    background-position-x: 0;
    background-repeat: no-repeat;
    transition: all .35s ease-in-out;
    pointer-events: none;
    z-index: 2;
    animation: shiningEffect1 2s ease-in-out normal ;
  }
  .explorer-back.active::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 100%;
    display: block;
    background: linear-gradient(250deg,hsla(0,0%,100%,0) 35%,hsla(0,0%,100%,.3) 50%,hsla(0,0%,100%,0) 65%);
    background-position-x: 0;
    background-repeat: no-repeat;
    transition: all .35s ease-in-out;
    pointer-events: none;
    z-index: 2;
    animation: shiningEffect2 2s ease-in-out normal ;
  }
  .explorer-back_card.active::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 100%;
    display: block;
    background: linear-gradient(250deg,hsla(0,0%,100%,0) 35%,hsla(0,0%,100%,.3) 50%,hsla(0,0%,100%,0) 65%);
    background-position-x: 0;
    background-repeat: no-repeat;
    transition: all .35s ease-in-out;
    pointer-events: none;
    z-index: 2;
    animation: shiningEffect2 2s ease-in-out normal ;
  }
  .rare-element::before{
    content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 20px solid #615f5f;
      z-index: 1;
  }
  .explorer-badge-img{
    position: absolute;
    width: 75px;
    height: 75px;
    bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    cursor: pointer;
    z-index: 5;
  }
  .explorer-flip-icon {
    /* position: absolute; */
    width: 40px;
    height: 40px;
    /* margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;*/
    cursor: pointer; 
  }
  .explorer-fullscreen-icon{
    /* position: absolute; */
    width: 40px;
    height: 40px;
    margin-right: 10px;
    /* margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
    cursor: pointer;
  }

  .explorer-download-icon{
    /* position: absolute; */
    width: 40px;
    height: 40px;
    margin-left: 10px;
    /* bottom: 28px;
    right: 28px; */
    cursor:pointer;
    display: none;
  }

  .explorer-card-image-container:hover .explorer-download-icon{
    display:block;
  }

  .explorer-back_card{
    animation: hideBack 2s ease-in-out normal;
    transition: all 1s;
    visibility: hidden;
    border-radius: 12px;
  }
  .explorer-back_card.active{
    visibility: visible;
  }
  .explorer-card-back-root {
    height: 100%;
    transform: rotateY(180deg);
    border-radius: 12px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: end;
    justify-content: flex-end;
    position: relative;
  }
  .explorer-card-icons-holder{
    position: absolute;
    inset: 0px;
    margin: auto;
    width: 100%;
    max-height: 40px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 5;
  }
  .explorer-dust-amount-container{
    font-size: 22px;
    position: absolute;
    max-width: 120px;
    padding: 0.7em;
    width: auto;
    gap: 5px;
    top: 2%;
    right: 0;
    left: 0;
    margin: auto;
    border-radius: 8px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border:solid 2px #fff;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.35);
  }

  .explorer-dust-text-element{
    margin: 0;
    font-size: 1em;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    font-family: poppins-semibold;
    color: #FFF;
    display: flex;
    gap: 5px;
    width: auto;
    align-items: center;
  }
  .explorer-dust-text-element img{
    max-width: 1.4em;
  }
  .explorer-dust-amount-container:hover {
    border-color: #fc6405;

  }
 
  .explorer-trait-container {
    position: absolute;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 5px;
    top: 10px;
    right: 10px;
    z-index: 10;
  }
  .explorer-trait-holder{
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border:solid 2px #fff;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.35);
  }
  .explorer-trait-holder:hover {
    border-color: #fc6405;
  }
  .explorer-trait-holder img{
    width: 30px;
    height: 25px;
    pointer-events: none;
  }
  .explorer-trait-card-root{
    width: 100%;
    height: 100%;
    border-radius: 12px;
    transform: rotateY(180deg);
    -webkit-background-position: center;
    background-position: center;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .explorer-close-button {
    width: 20.5px;
    height: 20.5px;
    cursor: pointer;
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 5;
    transform: rotateX(0deg);
  }
  .explorer-trait-card-content {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-color: #fc6405b3;
  }
  .explorer-trait-card-content-holder{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
   
  }
  
  .explorer-trait-card-trait-icon-holder {
    width: 72px;
    height: 72px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .explorer-trait-card-trait-icon-holder img{
    width: 100%;
    height: 100%;
  }
  .explorer-trait-card-title-holder{
      display: flex;
      justify-content: center;
  }
  .explorer-trait-card-title {
    margin: 0px;
    font-weight: 400;
    color: rgb(250, 250, 242);
    font-size: 36px;
    font-family: Poppins-semibold;
    line-height: 42px;
    text-align: center;
    width: fit-content;
    max-width: 250px;
    min-width: 250px;
  }
  .explorer-trait-card-description-holder{
    margin-top: 15px;
    height: 43%;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    overflow-y: auto;
  }

  .claim-button-holder{
    position: absolute;
    bottom: 2%;
  }

   .explorer-trait-card-description-holder::-webkit-scrollbar {
    width: 4px;
  }
  .explorer-trait-card-description-holder::-webkit-scrollbar-track {
    background: #ffffff49;
  } 
  .explorer-trait-card-description-holder::-webkit-scrollbar-thumb  {
    background: #ffffff;
  } 
  .explorer-trait-card-description{
    margin: 0px;
    font-weight: 400;
    color: rgb(250, 250, 242);
    font-family: poppins;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    max-width: 90%;
    min-width: 200px;
  }
  .explorer-badge-card-root {
    width: 100%;
    height: 100%;
    transform: rotateY(180deg);
    -webkit-background-position: center;
    background-position: center;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .explorer-badge-card-content{
    height: 100%;
  }
  .explorer-badge-card-description {
    margin: 0px;
    font-weight: 400;
    color: rgb(250, 250, 242);
    font-family: poppins;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    max-width: 90%;
    min-width: 200px;
    height: 250px;
  }


  .trait-claim-btn{

    color:white;
    border:solid 2px white;
    text-decoration: none;
    padding:10px 20px;
    border-radius:5px;

  }
  

  .trait-claim-btn:hover{
    background-color: white;
    color:#fc6405;
  }


  @keyframes shiningEffect1 {
    0%{
  
      left: -100%;
    }
   100%{
  
    left: 100%;
   }
  }
  @keyframes shiningEffect2 {
    0%{
  
      right: -100%;
    }
   100%{
    right: 100%;
   }
  }
  
  @keyframes hideBack {
   from{
    visibility: visible;
    }
   to{
      visibility: hidden;
    }
  }
  .explorer-flipped-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    transition: 2s;
  }
  .explorer-buttonHolder{
    position: fixed;
    height: 16px;
    width: 16px;
    top: 4%;
    left: 4%;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1rem;
    color: #fff;
    transition: color .3s ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 900px) {
  .explorer-download-icon{
    display: block;
  }
}
@media screen and (max-width: 550px) {
  /* .explorer-card-image-container {
      min-height: 300px;
  } */
  .explorer-dust-amount-container{
    font-size: 14px;
    width: 90px;
  }
  .explorer-dust-text-element{
    align-items: flex-start;
  }
  .explorer-dust-text-element img {
    max-width: 14px;
  }
 
}
  @media screen and (max-width: 450px) {
    /* .explorer-card-image-container {
        min-height: 300px;
    } */
    .explorer-explorer-scope {
      position: relative;
      height: 440px;
      transform-style: preserve-3d;
      animation: slid 5s linear infinite;
      transition: 2s all;
    }
    .explorer-arrow{
      display: none;
    }
    .explorer-dust-amount-container{
      font-size: 14px;
      width: 90px;
    }
    .explorer-dust-text-element{
      align-items: flex-start;
    }
    .explorer-dust-text-element img {
      max-width: 14px;
    }
   
  }
  @media screen and (max-width: 350px) {
    .explorer-badge-img{
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    }
    .explorer-explorer-scope {
      position: relative;
      /* swidth: 250px !important;
      height: 340px; */
      transform-style: preserve-3d;
      animation: slid 5s linear infinite;
      transition-property: position;
      transition: 2s;
    }
    .rare-element::before{
      content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 12px solid #615f5f;
        z-index: 1;
    }
    .explorer-dust-amount-container{
      font-size: 12px;
      width: 80px;
    }
    .explorer-dust-text-element{
      align-items: flex-start;
    }
    .explorer-dust-text-element img {
      max-width: 12px;
    }
  }

  .c-xlarge .explorer-trait-holder{
    width: 35px;
    height: 35px;
  }
  .c-xlarge .explorer-trait-card-trait-icon-holder{
    margin-bottom: 10px;
  }
  .c-xlarge .explorer-trait-card-title{
    font-size: 32px;
  }
  .c-xlarge .explorer-dust-amount-container{
    font-size: 12px;
    width: 80px;
  }
  .c-xlarge  .explorer-dust-text-element{
    align-items: center;
  }
  .c-xlarge  .explorer-dust-text-element img {
    max-width: 12px;
  }
  
  .c-medium .explorer-trait-holder{
    width: 35px;
    height: 35px;
  }
  .c-medium .explorer-trait-card-trait-icon-holder{
    margin-bottom: 10px;
  }
  .c-medium .explorer-trait-card-title{
    font-size: 32px;
  }
  .c-medium .explorer-trait-card-description .badge-card-description{
    line-height: 23px;
  }
  .c-medium .explorer-dust-amount-container{
    font-size: 12px;
    width: 80px;
  }
  .c-medium  .explorer-dust-text-element{
    align-items: center;
  }
  .c-medium  .explorer-dust-text-element img {
    max-width: 12px;
  }
  .c-small .explorer-trait-holder{
    width: 30px;
    height: 30px;
  }
  .c-small .explorer-dust-amount-container{
    max-width: 80px;
  }
  
  
  
  .c-small .explorer-dust-amount-container{
    font-size: 12px;
    width: 80px;
  }
  .c-small .explorer-dust-text-element{

    align-items: center;
  }
  .c-small .explorer-dust-text-element img {
    max-width: 12px;
  }

  .c-xsmall .explorer-trait-holder{
    width: 30px;
    height: 30px;
  }
  .c-xsmall .explorer-dust-amount-container{
    max-width: 80px;
  }
 
  .c-xsmall .explorer-dust-amount-container{
    font-size: 12px;
    width: 70px;
  }
  .c-xsmall .explorer-dust-text-element{
    align-items: center;
    gap: 2px;
  }
  .c-xsmall .explorer-dust-text-element img {
    max-width: 10px;
  }