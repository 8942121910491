.card-root {
  position: relative;
  width: 100%;
  border-radius: 15px;
  border: 1px solid rgba(161, 161, 161, 0.48);
}
.image-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  border-radius: 15px;
  background-color: #152D2E;
}
.image-container:hover .card-details{
  opacity: 1;
  transition: all .25s;
}
.image-container img{
  max-width: 100%;
}
.card-content {
  width: 100%;
  margin-top: -4px;
  z-index: 1;
  background-color: none;
  border-radius: 15x;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:10px;
  font-family: boucherie-block;
}
.card-content p{
  margin: 0;
}
.card-details{
    opacity: 0;
    transition: all .25s;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000000ea;
    z-index: 0;
    border-radius:15px;
    overflow-y: auto;
}
.card-details ul{
  list-style: none;
  padding: 0;
  text-align: center;
}
.trait-type, .trait-value{
  margin: 0;
}
.trait-type{
  color:rgb(112, 112, 112)
}
.trait-value{
  color: #fff;
}

.card-details::-webkit-scrollbar-thumb {
  background: none
}
.custom-filter-select select{
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border: none;
  background: transparent;
  box-shadow: none;
  color: #000;
  outline: none;
}
.select-parent{
  border: 2px solid #000;
  border-radius: 8px;
  padding-right: 5px;
}
.select-parent:focus-within{
  border: 2px solid #fa8006;
}