.decor-title{
	height: 85px;
	display: flex;
	flex-direction: row;
	width: max-content;
	margin-left: auto;
	margin-right: auto;
}

.decor-title .title-text{
	display: inline-flex;
	height: 85px;
	font-weight: 400;
	font-family: boucherie-block;
	padding: 0;
	background-image: url('../assets/images/decor-mid.png');
	background-size:contain;
	background-repeat: repeat-x;
}

.title-text .content{
  font-size: 36px;
  line-height: 36px;
  align-self: center;
}

.decor-title .decor {
	display: inline-block;
	width: 85px;
	height: 85px;
	background-image: url('../assets/images/decor-end.png');
	background-size:cover;
}

.decor-title .en{
  -moz-transform:scaleX(-1) scaleY(-1);
  -o-transform:scaleX(-1) scaleY(-1);
  -webkit-transform:scaleX(-1) scaleY(-1);
  transform:scaleX(-1) scaleY(-1);
  margin-left: -1px;
}
.decor-title .st{
  margin-right: -1px;
}


@media (max-width: 508px){
  .title-text .content{
    font-size: 30px;
    line-height: 30px;
  }

  .decor-title{
	max-width:370px;
  }
  
}

@media (max-width: 415px){
  .title-text .content{
    font-size: 22px;
    line-height: 22px;
  }

  .decor-title{
	max-width:310px;
  }
}