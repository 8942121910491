.mask-box {
  border-radius: 20px;
  position: relative;
  background: transparent;
  border: 2px solid #CACCB5;
}
.mask-box.active {
  background: transparent;
  border: 2px solid #FC6405;
}
.mask-box .mask {
  width: 35px;
  height: 26px;
  border-radius: 10px;
  background: #CACCB5;
  position: absolute;
  transition: all 0.5s ease;
}
.mask-box.active .mask{
  width: 35px;
  height: 26px;
  border-radius: 10px;
  background: #FC6405;
  position: absolute;
  transition: all 0.5s ease;
}
.mask-box .MuiButton-root {
  border-radius: 20px;
  width: 30px;
  height: 25px;
  font-family: Boucherie-block;
  transition: all 0.2s 0.1s ease;
  min-width: unset;
}
.mask-box .MuiButton-root:hover {
  opacity: 1;
}
