.explorer-dust-layer-container {
  font-size: 64px;
}

.explorer-dust-close-button {
  width: 0.6em;
  height: 0.6em;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 5;
  transform: rotateX(0deg);
}

.explorer-dust-layer-icon {
  height: 2em;
  margin-bottom: 0.5em;
}

.explorer-dust-layer-title {
  margin: 0;
  font-weight: 400;
  color: #fafaf2;
  font-size: 1em;
  font-family: "boucherie-block";
  line-height: 1.03em;
  text-align: center;
  max-width: 100%;
  margin-bottom: 0.8em;
}

.explorer-dust-layer-description {
  margin: 0;
  font-weight: 400;
  color: #fafaf2;
  font-family: poppins;
  font-size: 0.8em;
  line-height: normal;
  text-align: center;
  min-width: 200px;
  max-height: 6em;
  margin-bottom: 0.8em;
}

.explorer-dust-amount-value-holder {

  margin-top: 0.5em !important;
  padding: 0.4em 0.6em;
}

.explorer-dust-amount-value-holder img {
  height: 1em;
}

.explorer-dust-amount-value {
  font-size: 0.7em;
  font-family: 'poppins-semibold';
  margin: 0;
  line-height: normal;
}
@media screen and (max-width: 700px) {
  .explorer-dust-layer-container {
    font-size: 50px;
  }

}

@media screen and (max-width: 550px) {
  .explorer-dust-layer-container {
    font-size: 40px;
  }

}

@media screen and (max-width: 430px) {
  .explorer-dust-layer-container {
    font-size: 34px;
  }

}
@media screen and (max-width: 350px) {
  .explorer-dust-layer-container {
    font-size: 27px;
  }

}
.c-xlarge .explorer-dust-layer-container {
  font-size: 23px;
}
.c-large .explorer-dust-layer-container {
  font-size: 23px;
}
.c-large .explorer-dust-layer-description {
  font-size: 0.7em;
}
.c-medium .explorer-dust-layer-container {
  font-size: 24px;
}
.c-small .explorer-dust-layer-container {
  font-size: 23px;
}
.c-xsmall .explorer-dust-layer-container {
  font-size: 16px;
}
.c-xsmall .explorer-dust-layer-description {
  min-width: auto;
}
.c-xsmall .explorer-dust-amount-value {
  font-size: 1em;
}