.wallet-badge-image{
    position: absolute;
    width: 25px;
    bottom: 10px;
    left: 0px;
    right: 0;
    margin: auto;
}
.orange-badge-image{
    position: absolute;
    width: 55px;
    top:24px;
    right: 20px;
    margin: auto;
}
.orange-badge-number{
    position: absolute;
    width: 55px;
    top:3px;
    right: 0px;
    margin: auto;
    color:#fff;
}