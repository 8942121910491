.counter-body{
    font-size: 1em;
    line-height: 1em;
    vertical-align: center;
}

.counter-body .counter-item{
    display: inline-block;
    width: 12px;
    height: 1.6em;
    margin: 0 2px 0 0;
    background-color: #ff6b2b3d;
    border-radius: 3px;
}

.counter-item.active{
    background-color: #FF692B;
}