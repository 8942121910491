.filter-container{
    position: fixed;
    height: 180px;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    z-index: 2;
    width: 100%;
    background-color: #faf9f5f5;
    box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 29%);
    max-width: 1140px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    z-index: 3;
}
.filter-container-inner{
    height: 60%;
    margin: 10px;
    overflow: auto;
    display: flex;
}
.filter-container-inner::-webkit-scrollbar {
    width: 2px!important;
    height: 8px;
  }
.filter-container-inner::-webkit-scrollbar-track {
background: transparent;
} 
/* Handle */
.filter-container-inner::-webkit-scrollbar-thumb {
background: rgb(252, 108, 3);
border-radius: 10px;
}

.filter-container select{
    font-size: 16px;
}
.filter-container label{
    font-size: 14px;
    min-height: 40px;
    font-weight: 400;
    font-weight: 600;
margin-top: 1px;
}
.select-parent{
    margin-top:10px;
}
.custom-filter-select label{
    font-weight: 600;
}

