.counter-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	gap: 32px 32px;
	margin-bottom: 40px;
}

.counter-circle {
	width: 85px;
	height: 85px;
	border: 4px solid  #D2D4C2;
	border-radius: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1;
}

.counter-text {
	font-family: 'boucherie-block';
	font-size: 16px;
	line-height: 23px;
	color: #CACCB5;
	z-index: 1;
}

.counter-text-value {
	font-family: 'boucherie-block';
	font-size: 32px;
	color: #CACCB5;
	letter-spacing: -2px;
	line-height: 35px;
}
.counter-left-arrow{
	position: relative;
    top: 0;
    left: 35px;
}
.counter-right-arrow{
	position: relative;
    top: 0;
    right: 35px;
}
@media (max-width: 650px) {
	.counter-left-arrow{
	display: none;
	}
	.counter-right-arrow{
		display: none;
	}
}