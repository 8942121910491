input {
    border: 1px solid #050000;
    padding-left: 10px;
    border-radius: 10px;
    font-size: 20px;
} 
input::placeholder{
    font-size: 12px;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-right: 10px !important;
}
#physical-input {
    min-height: 45px;
    max-width: 320px;
    max-height: 46px;
    width: 100%;
    height: 100%;
}
#physical-input-small{
    max-width: 145px;
    min-height: 45px;
    width: 100%;
    height: 100%;
}
@media (max-width: 500px) {
    #physical-input {
        min-height: 45px;
        max-width: 320px;
        max-height: 46px;
        width: unset;
        height: 100%;
    }
}