body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  color: #FF692B;
}
.decorated-text { 
  color: #FF692B;
  /* text-decoration: underline; */
  cursor: pointer
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.firstList li{
  list-style-type: disc;
}
.firstList ul{
  padding-left: 2rem;
}
.secondList ul{
  padding-left: 2rem;
  list-style-type:circle;
  margin-bottom: 1rem;
}
.secondList ul li{
  margin-bottom: 1rem;
}
.secondList ul ul ul li{
  margin-bottom: 0rem;
}
.secondList ul ul ul li{
  list-style-type:square;
}
td{
  border:1px solid #D2D4C2;
  padding:10px;
}
.grBOFc{
  align-items: center;
}
@font-face {
  font-family: 'bau';
  src: local('bau'), url(./assets/fonts/Bau-Regular.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'poppins';
  src: local('poppins'), url(./assets/fonts/Poppins.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'poppins-semibold';
  src: local('poppins-semibold'),
    url(./assets/fonts/Poppins-SemiBold.ttf) format('truetype');
    font-display: swap;
}

/* @font-face {
  font-family: 'poppins-medium';
  src: local('poppins-medium'),
    url(./assets/fonts/Poppins-Medium.ttf) format('truetype');
    font-display: swap;
}

@font-face {
  font-family: 'cubano';
  src: local('cubano'), url(./assets/fonts/Cubano.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'believer';
  src: local('believer'), url(./assets/fonts/BELIEVER.TTF) format('truetype');
  font-display: swap;
} */