
.header-animation{  
    position: absolute;
    z-index: 1;
    right:  0;
    margin-top: 0;
    top: 0;
}
.header-animation.grd-logo{
    z-index: 0;
}
.header-animation-img{
    opacity: 0;
}
.header-animation-img.grd-logo{
    opacity: 1;
}
.active-header {
 animation: animation 5s;
}
.header-social-icons {
    width: 23px;
    height: 25px;
    cursor: pointer;
}
.grd-logo-box{
    width: 45%;
}
.grd-logo{
    text-align: center;
}
.grd-logo img{
    width: 85%;
    padding-top: 50px;
}
@keyframes animation {
    0%{
        opacity: 0;
    }
    30%{
        opacity: 1;
    }
    70%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    } 
    /* 0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    } */
}
th:first-child { border-top-left-radius: 15px; }
th:last-child { border-top-right-radius: 15px; }
tr:first-child  { border-top-right-radius: 15px; }
tr:last-child td:first-child { border-bottom-left-radius: 15px; }
tr:last-child td:last-child { border-bottom-right-radius: 15px; }
tr:last-child td:first-child{border-bottom:2px solid !important}
tr:last-child td:last-child{border-bottom:2px solid !important}
th:first-child{border-top:2px solid !important}
th:last-child{border-top:2px solid !important}
td:first-child {border-right: 1px solid !important}
th:first-child{border-right: 1px solid !important}
td:last-child {border-left: 1px solid !important}
th:last-child{border-left: 1px solid !important}
.decorated-text:hover{
color: #FCFCF9;
}
.bannerImg{
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 2;
    animation: 15s floating linear infinite;
}
.bannerImg img{
    max-height: 880px;
}
.serpent{
    position: absolute;
    width: 100%;
    text-align: center;
    top: 125px;
    z-index: 3;
    animation: 15s floatingRev linear infinite;
}
.serpent img{
    max-height: 525px;
}
@keyframes floatingRev {
    0%{
        transform: translateY(20px);
    }
   50%{
  
    transform: translateY(0);
   }
    100%{
  
    transform: translateY(20px);
   }
  }
@keyframes floating {
    0%{
        transform: translateY(0);
    }
   50%{
  
    transform: translateY(20px);
   }
    100%{
  
    transform: translateY(0);
   }
  }
@media (max-width: 1024px) {
    .grd-logo-box{
        display: none;
    }
}

@media (max-width: 768px) {
    .grd-logo-box{
        width: 45%;
    }
}
@media (max-width: 500px) {
    .grd-logo-box{
        width: 40%;
    }
}

@media (max-width: 500px) {
    .header-animation{ 
        top: 10%
     }
    .grd-logo-box{
        width: 45%;
    }
}